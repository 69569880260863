<template>
  <div class="max-w-wrapper-sm mx-auto">
    <!-- eslint-disable vue/no-v-html -->
    <ul
      class="flex gap-4"
      :class="tabWrapperClass"
    >
      <li
        v-for="(tab, tabIndex) in tabs"
        :key="tabIndex"
        :class="[
          component.tabs,
          {
            'border-b-2 border-b-success font-bold': currTab === tab?.key,
            '!bg-dark text-white': currTab === tab?.key && theme === 'dark',
            [tabClassActive]: currTab === tab?.key,
          },
          tabClass
        ]"
        @click="currTab = tab?.key"
      >
        <p v-html="tab?.label"></p>
      </li>
    </ul>

    <slot :name="currTab" />
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsTabs'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'dark',
    validator: value => ['light', 'dark'].includes(value)
  },

  tabWrapperClass: {
    type: String,
    default: ''
  },

  tabClass: {
    type: String,
    default: ''
  },

  tabClassActive: {
    type: String,
    default: ''
  },

  tabsFullWidth: {
    type: Boolean,
    default: false
  },

  tabs: {
    type: Array,
    default: () => (['Tab 1', 'Tab 2'])
  },

  // should be kebab-case
  forceCurrTab: {
    type: String,
    default: ''
  }
})

const defaulTab = computed(() => {
  if (props?.forceCurrTab) {
    return props.forceCurrTab
  }

  const tab = props.tabs?.[0]

  return tab?.key || tab
    ? strToKebabCase(tab?.key || tab)
    : ''
})

const currTab = ref(defaulTab.value)

const component = computed(() => {
  const tabs = (() => {
    const main = 'cursor-pointer text-center py-2 rounded-t-xl px-5'

    const color = getKey(props.theme, {
      light: 'bg-gray-350',
      dark: 'bg-white border-l border-r border-t border-gray-400'
    })
    return `${main} ${color}`
  })()

  return {
    tabs
  }
})

defineExpose({
  currTab
})
</script>
